import React, { useState, useEffect } from 'react'
import { Timeline, Event } from 'react-trivial-timeline'

export default function TimeLine(props) {
  const [events, setEvents] = useState([])

  useEffect(() => {
    if (!props || !props.events || !props.events.length) return

    setEvents(props.events)
  }, [props])

  return (
    <div>
      {events.map((e, i) => {
        return (
          <Timeline lineColor="black" key={i}>
            <Event
              interval={`${e.startDate} - ${e.endDate}`}
              subtitle={e.name}
              title={''}
            ></Event>
          </Timeline>
        )
      })}
    </div>
  )
}
